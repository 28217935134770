import { Component, OnInit,OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate, animation, query } from '@angular/animations'

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  animations: [
    trigger('carousel', [
      state('stay', style({
        marginLeft: '-1050px'
      })),
      state('stayR', style({
        marginLeft: '-1050px'
      })),
      state('moveLeft', style({
        marginLeft: '-1050px'
      })),
      state('moveRight', style({
        marginLeft: '-1050px'
      })),
      transition('* => moveLeft', 
        animate('1000ms ease-in-out', style({marginLeft: '-2100px'})),
      ),
      transition('* => stay', 
        animate('1000ms ease-in-out', style({marginLeft: '-2100px'})),
      ),
      transition('* => moveRight', animate('500ms ease-in-out', style({
        marginLeft: '0px'
      })))
    ]),
    trigger('showBg',[
      state('hide',style({
        top: "332px",
        opacity: '0',
      })),
      state('show',style({
        top: "0",
        opacity: '1',
      })),
      transition('hide=>show',[
        animate(500),
       ]),
      transition('show=>hide',[
        animate(500),
       ]
      )
    ])
  ]
})
export class HomePageComponent implements OnInit, OnDestroy {

  public bannerImgs : any[];

  public taskListData: any[];

  public state = 'stay';

  public isHover = false;

  public timer: any;

  constructor() { 
    this.bannerImgs = [
       "../../assets/images/img1.jpg",
       "../../assets/images/img2.jpg",
       "../../assets/images/img3.jpg",
       "../../assets/images/img4.jpg"
    ]
    
    this.taskListData = [
      {
        imgUrl: '../../assets/images/b1.png',
        title: '网站建设',
        isActive: false,
        desc: [
          '专注于企业网站建设创新',
          '注重网站排名效果，注重售后服务',
          '可完美兼容PC、Pad、手机等不同终端',
          '提供一站式、专业化的服务'
        ]
      },
      {
        imgUrl: '../../assets/images/setting.png',
        title: '系统开发',
        isActive: false,
        desc: ['erp管理系统','OA管理系统']
      },
      {
        imgUrl: '../../assets/images/b3.png',
        title: 'APP、小程序',
        isActive: false,
        desc: [
          '微信小程序、微场景、微杂志',
          '微网站（微官网、微站）、手机网站',
          '微商城、APP开发',
          '无微不至'
        ]
      },
      {
        imgUrl: '../../assets/images/b4.png',
        title: '网站优化和推广',
        isActive: false,
        desc: [
          '通过一整套技巧性、持续性的工作',
          '提升网站对搜索引擎的吸引力',
          '确保指定关键词排名百度首页',
        ]
      }
    ]
  }

  ngOnInit() {
    this.autoPlay();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  autoPlay(): void {
    const me = this;
    this.isHover = false;
    this.timer = setInterval(() => {
      me.state = me.state === 'stay' ? 'moveLeft' : 'stay';
    }, 3000);
  }

  playNext(): void {
    this.state = this.state === 'stay' ? 'moveLeft' : 'stay';
  }

  playPre(): void {
    this.state = 'moveRight';
  }

  stopPlay():void {
    clearInterval(this.timer);
    this.isHover = true;  
  }

  afterPlay():void {
    if (this.state === 'stay' || this.state === 'moveLeft') {
      this.bannerImgs.push(this.bannerImgs[0]);
      this.bannerImgs.shift();
    } else if (this.state === 'moveRight') {
      const last = this.bannerImgs.length - 1;
      this.bannerImgs.unshift(this.bannerImgs[last]);
      this.bannerImgs.pop();
      this.state = 'stayR';
    }
  }

  //鼠标移入事件
  wheelHover(item):void {
    for(let x of this.taskListData){
       x.isActive = false;
      if(x.title == item.title){
         x.isActive = true;
      }
    }
  }

  //鼠标移出事件
  wheelLeave(item):void {
    for(let x of this.taskListData){
      if(x.title == item.title){
         x.isActive = false;
      }
    }
  }


  

}
