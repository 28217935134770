import { Component, OnInit, Output } from '@angular/core';
import { PlatformLocation} from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router' 
import { trigger } from '@angular/animations';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css'],
  // animations: [
  //   trigger('jumpSpirit',[

  //   ])
  // ]
})
export class HeaderBarComponent implements OnInit {

  companyName: string;

  menuTabs: any;

  constructor(
              public router: Router, 
              public Location: PlatformLocation,
              public route: ActivatedRoute
              ) {

    this.companyName = '上海车到网络科技有限公司';

    this.menuTabs = [
      {
        title: '首页',
        enTilte: 'HOME',
        path: '/homePage',
        isSelected: true,
        isShowShadow: false,
        hoverBgUrl: '',
      },
      {
        title: '公司简介',
        enTilte: 'ABOUT',
        path: '/aboutUs',
        isSelected: false,
        isShowShadow: false,
        hoverBgUrl: './assets/m_hover.jpg',
      },
      {
        title: '开发案列',
        enTilte: 'CASE',
        path: '/developCase',
        isSelected: false,
        isShowShadow: false,
        hoverBgUrl: './assets/m_hover.jpg',
      },
      {
        title: '联系我们',
        enTilte: 'CONTACT US',
        path: '/contractUs',
        isSelected: false,
        isShowShadow: false,
        hoverBgUrl: './assets/m_hover.jpg',
      }
    ]
   }
  ngOnInit() {
    for(let item of this.menuTabs){
      item.isSelected = false;
      item.hoverBgUrl = "./assets/m_hover.jpg";
      if(item.path == this.Location["location"].pathname){
        item.isSelected = true;
        item.hoverBgUrl = '';
        this.router.navigate([item.path]) 
      }else if(this.Location["location"].pathname == '/' && item.title == '首页'){
        item.isSelected = true;
        item.hoverBgUrl = '';
        this.router.navigate([item.path])  
      } 
    }

    this.Location.onPopState(()=>{
      for(let item of this.menuTabs){
        item.isSelected = false;
        item.hoverBgUrl = "./assets/m_hover.jpg";
        if(item.path == this.Location["location"].pathname){
          item.isSelected = true;
          item.hoverBgUrl = '';
          this.router.navigate([item.path]) 
        } 
      }
   })
  }

  //切换tab
  changeTab(tab: any){
     for(let item of this.menuTabs) {
        item.isSelected = false;
        item.hoverBgUrl = "./assets/m_hover.jpg";
        if(item.title == tab.title){
           item.isSelected = true;
           item.hoverBgUrl = '';
           this.router.navigate([item.path],{relativeTo: this.route})
        }
     }
  }

}
