import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HashLocationStrategy,LocationStrategy} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { HomePageComponent } from './home-page/home-page.component';

import { AppRoutingModule } from './app-routing.module';
import { FootBarComponent } from './foot-bar/foot-bar.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContractUsComponent } from './contract-us/contract-us.component';

import { BaiduMapModule  } from 'angular2-baidu-map';
import { DevelopCaseComponent } from './develop-case/develop-case.component'


@NgModule({
  declarations: [
    AppComponent,
    HeaderBarComponent,
    HomePageComponent,
    FootBarComponent,
    AboutUsComponent,
    ContractUsComponent,
    DevelopCaseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BaiduMapModule.forRoot({ak: 'S9wFEjlIBYDP3yk5Y26Gi8yR3ldBglwG'}),
  ],
  providers: [
    {
      provide:LocationStrategy,
      useClass:HashLocationStrategy
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
