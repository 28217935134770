import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomePageComponent } from './home-page/home-page.component'
import { AboutUsComponent } from './about-us/about-us.component'
import { ContractUsComponent } from './contract-us/contract-us.component'
import { DevelopCaseComponent } from './develop-case/develop-case.component'


const routes: Routes = [
    { path: 'homePage', component: HomePageComponent },
    { path: 'aboutUs', component: AboutUsComponent },
    { path: 'developCase', component: DevelopCaseComponent },
    { path: 'contractUs', component: ContractUsComponent },
    { path: '', redirectTo:'homePage', pathMatch: 'full' },
    { path: '**', component: HomePageComponent },
]

@NgModule({
    imports: [ RouterModule.forRoot( routes, { enableTracing : true })],
    exports: [ RouterModule ]
})

export class AppRoutingModule {}