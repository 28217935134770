import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-develop-case',
  templateUrl: './develop-case.component.html',
  styleUrls: ['./develop-case.component.css']
})
export class DevelopCaseComponent implements OnInit {

  public caseListArray : any[]

  constructor() { 
    this.caseListArray = [
      {
        caseName: '上海合顺股权投资基金管理有限公司',
        imgUrl: '../../assets/images/caseImg1.jpg',
        desc: '上海合顺股权投资基金管理有限公司是位于中国第一大城市，中国的经济、金融、贸易和航运中心上的基金管理公司成立于2011年11月，主要负责面向全国提供优质的产品和良好的服务。截至2019年1月，我司为上海合顺股权投资基金管理有限公司开发的模拟盘/实盘交易网站论坛已达到注册用户接近2000。'
      },
      {
        caseName: '上海国时资产管理有限公司',
        imgUrl: '../../assets/images/caseImg2.jpg',
        desc: '上海国时资产管理有限公司是一家具有央企背景的证券私募及资产管理机构，公司成立于2013年06月，主要负责面向全国提供优质的产品和良好的服务。专注于FOF/MOM管理系统开发及基金管理、股票投资基金、项目投资管理等领域。截至2019年1月，我司为上海国时资产管理有限公司开发的实盘大赛网站已达到注册用户接近1000。日均数据200条以上。'
      },
      {
        caseName: '上海煌昱投资有限公司',
        imgUrl: '../../assets/images/caseImg3.jpg',
        desc: '上海煌昱投资有限公司可追溯至2005年建立的上海煌昱资产管理有限公司，是一家提供持续、稳健收益率的公司。核心成员均有10年以上的投资市场经验，曾在金融投资市场创造过优良业绩。截至2019年1月，我司为上海煌昱投资有限公司开发的实盘大赛网站已达到注册用户接近500。日均数据100条以上。'
      }
    ]
  }

  ngOnInit() {
  }

}
