import { Component, OnInit } from '@angular/core';
import { MapOptions, NavigationControlOptions, Point, BMarker, BMapInstance, Animation, MarkerOptions, ControlAnchor, NavigationControlType, BNavigationControl, MapTypeControlType, MapTypeControlOptions, OverviewMapControlOptions, ScaleControlOptions } from 'angular2-baidu-map'

@Component({
  selector: 'app-contract-us',
  templateUrl: './contract-us.component.html',
  styleUrls: ['./contract-us.component.css']
})
export class ContractUsComponent implements OnInit {

    public opts: MapOptions
    public markers: Array<{ point: Point; options?: MarkerOptions }>
    public controlOpts: NavigationControlOptions
    public scaleOpts: ScaleControlOptions
  
    constructor() {

      this.opts = {
        centerAndZoom: {
          lat: 31.344826,
          lng: 121.440184,
          zoom: 16,
        },
        enableScrollWheelZoom: true,
        disableDoubleClickZoom: false,
        enableKeyboard: true,
      }

      this.markers = [
        {
          point: {
            lat: 31.344826,
            lng: 121.440184,
          }
        }
      ]

      this.controlOpts = {
        anchor: ControlAnchor.BMAP_ANCHOR_TOP_LEFT,
        type: NavigationControlType.BMAP_NAVIGATION_CONTROL_LARGE
      }
      
      this.scaleOpts = {
        anchor: ControlAnchor.BMAP_ANCHOR_BOTTOM_LEFT
      }

    }

    public controlLoaded(control: BNavigationControl): void {
      
    }

    public setAnimation(marker: BMarker): void {
      marker.setAnimation(Animation.BMAP_ANIMATION_BOUNCE)
    }
  
    public showWindow({ marker, map }: { marker: BMarker; map: BMapInstance }): void {
      map.openInfoWindow(
        new window.BMap.InfoWindow('详细地址：上海市宝山区呼兰西路100号2号楼', {
          offset: new window.BMap.Size(0, -30),
          title:  '上海车到网络科技有限公司',
        }),
        marker.getPosition()
      )
    }

  ngOnInit() {

  }

}
